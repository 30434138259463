.famous-course-component {
  background: linear-gradient(#f0f1ff, white);
  padding: 59px 0 55px 0;

  .title {
    font-family: "Manrope-Bold", sans-serif;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .text {
    font-family: "Neue-SemiBold", sans-serif;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    margin: 12px 0 32px 0;
  }

  form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    select,
    input {
      border: 1px solid #7180964d;
      height: 56px;
      color: #444444;
      font-family: "Manrope-SemiBold", sans-serif;
      border-radius: 8px;
    }

    select:nth-child(2) {
      margin: 0 30px;
    }

    button {
      background-color: #262e6b;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 10px;
      width: 200px;
      margin-left: 30px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .course-box {
    margin-bottom: 30px;
    border: 1px solid #dddddd;
    background: #ffffff;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    height: 176px;
    border-radius: 24px;
    transition: 0.3s linear;

    &:hover {
      box-shadow: 0px 4px 20px 10px #0000000d;
    }

    .poster {
      width: 170px;
      border-radius: 10px;
    }

    .course-box-for-text {
      margin-left: 20px;
    }

    .course-name {
      font-family: "Manrope-SemiBold", sans-serif;
      font-size: 20px;
      line-height: 27px;
    }

    .course-country {
      margin: 14px 0;
      border: 1px solid #262e6b;
      padding: 4px 12px;
      border-radius: 20px;
      width: 220px;
      display: flex;
      align-items: center;

      img {
        width: 25px;
        border-radius: 2px;
      }

      .country-name {
        font-family: "Manrope-Bold", sans-serif;
        font-size: 14px;
      }
    }

    .course-date {
      font-family: "Manrope-Medium", sans-serif;
      font-size: 16px;
      line-height: 22px;

      span {
        font-family: "Manrope-Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

@media (max-width: 768px) {
  .famous-course-component {
    .title {
      font-size: 18px;
      line-height: 26px;
    }

    .text {
      font-size: 32px;
      line-height: 46px;
    }

    form {
      display: block;

      select:nth-child(2) {
        margin: 20px 0;
      }

      button {
        margin: 30px 0;
        width: 100%;
      }
    }

    .course-box {
      display: flex;
      flex-direction: column;
      padding: 20px;
      height: auto;
      border-radius: 24px;

      .course-box-for-text {
        margin-left: 0;
        margin-top: 20px;
      }

      .course-name {
        font-size: 18px;
        line-height: 24px;
      }

      .course-country {
        padding: 4px 8px;
        width: auto;
      }

      .course-date {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
