.course-detail-page{
  background: linear-gradient(#F0F1FF, white);
  padding: 48px 0 80px 0;

  .course-line{
    display: flex;
    align-items: center;
    a{
      text-decoration: none;
      font-size: 14px;
      line-height: 22px;
      color: #808185;
    }
    img{
      margin: 0 8px;
    }
    b{
      font-family: "Manrope-Bold",sans-serif;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .detail-box{
    border: 1px solid #E8E8E8;
    border-radius: 16px;
    margin-top: 20px;
    background-color: white;
    padding: 40px;

    .detail-box-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      button{
        width: 126px;
        height: 28px;
        background-color: black;
        color: white;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
      }
      .date{
        color: #00000059;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }
    }

    .title{
      font-family: "Manrope-Bold",sans-serif;
      font-size: 32px;
      line-height: 48px;
      color:#262E6B;
    }
    .text{
      font-size: 20px;
      line-height: 32px;
      margin: 16px 0 49px 0;
    }

    .table{
      margin-bottom: 40px;
      th,td{
        padding: 10px 14px;
      }
      th{
        color: #262E6B;
      }
    }

    .step-box{
      display: flex;
      align-items: center;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .register-btn{
      width: 490px;
      height: 56px;
      background: linear-gradient(180deg, #262E6B 0%, #1A1F46 100%);
      color: white;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 9px;
      display: block;
      margin: 40px auto 0 auto;
    }
  }

  .country-box{
    margin-bottom: 32px;
    .country-title{
      font-size: 24px;
      font-weight: 600;
      line-height: 29.05px;
      margin-bottom: 16px;
    }
    .country-content{
      line-height: 20px;
      margin-bottom: 16px;
    }
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    position: absolute;
    left: -96px;
    top: 45%;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #444444;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/assets/img/carousel-black-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      transform: rotate(180deg);
      background-color: #262e6b;
      background-image: url("/assets/img/carousel-icon.svg");
    }
    span {
      display: none;
    }
  }
  .alice-carousel__next-btn-item {
    left: auto;
    right: -96px;
    transform: rotate(180deg);
    &:hover {
      transform: rotate(0);
      background-color: #262e6b;
      background-image: url("/assets/img/carousel-icon.svg");
    }
  }
}

@media (max-width: 768px) {
  .course-detail-page{
    background: linear-gradient(#F0F1FF, white);
    padding: 50px 12px;


    .detail-box{
      padding: 30px 24px;

      .detail-box-header{
        .date{
          margin-left: 16px;
        }
      }

      .title{
        font-size: 26px;
        line-height: 34px;
      }
      .text{
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 30px 0;
      }

      .table{
        margin-bottom: 30px;
        th,td{
          padding: 8px 12px;
        }
      }

      .step-box{
        font-size: 14px;
        line-height: 20px;
      }

      .register-btn{
        width: 100%;
        height: auto;
        padding: 10px 40px;
        font-size: 16px;
        line-height: 22px;
      }
    }


    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
      position: relative;
      left: calc(100% - 48px);
      right: 0;
      top: 20px;
    }
    .alice-carousel__next-btn-item {
      right: 0;
      left: auto;
    }
  }
}