.news-detail-page{
  background: linear-gradient(#F0F1FF, white);
  padding: 48px 0 80px 0;

  .course-line{
    display: flex;
    align-items: center;
    a{
      text-decoration: none;
      font-size: 14px;
      line-height: 22px;
      color: #808185;
    }
    img{
      margin: 0 8px;
    }
    b{
      font-family: "Manrope-Bold",sans-serif;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .detail-box{
    border: 1px solid #E8E8E8;
    border-radius: 16px;
    margin-top: 20px;
    background-color: white;
    padding: 40px;

    .detail-box-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      button{
        width: 126px;
        height: 28px;
        background-color: black;
        color: white;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
      }
      .date{
        color: #00000059;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }
    }

    .title{
      font-family: "Manrope-Bold",sans-serif;
      font-size: 32px;
      line-height: 48px;
      color:#262E6B;
      margin-bottom: 49px;
    }
    .text{
      font-size: 20px;
      line-height: 32px;
      margin: 16px 0 49px 0;
    }

    .sub-title{
      font-family: "Manrope-Bold",sans-serif;
      font-size: 24px;
      line-height: 32px;
      margin: 24px 0;
    }
    .reason-text{
      font-family: "Manrope-Medium",sans-serif;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      span:first-child{
        margin-right: 4px;
      }
    }
  }
}

@media (max-width: 768px) {
  .news-detail-page{
    background: linear-gradient(#F0F1FF, white);
    padding: 50px 12px;


    .detail-box{
      padding: 30px 24px;

      .detail-box-header{
        .date{
          margin-left: 16px;
        }
      }

      .title{
        font-size: 26px;
        line-height: 34px;
      }
      .text{
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 30px 0;
      }

      .sub-title{
        font-size: 20px;
        line-height: 26px;
      }

    }
  }
}