.our-values-component{
  margin: 97px 0 50px 0;
  .main-text {
    font-family: "Neue-Regular", sans-serif;
    padding-bottom: 60px;
    line-height: 22px;
    text-align: center;
    width: 900px;
    margin: 0 auto;
  }

  .box{
    height: 390px;
    box-shadow: 0px 4px 22px 15px #00000008;
    padding: 40px 32px;
    box-sizing: border-box;
    margin-bottom: 28px;
    border-radius: 12px;
    .title{
      font-family: "Neue-SemiBold",sans-serif;
      font-size: 24px;
      line-height: 31px;
      margin: 24px 0 20px 0;
    }

    .text{
      font-family: "Neue-Regular",sans-serif;
      line-height: 24px;
    }
  }
}

@media (max-width: 768px) {
  .our-values-component{
    margin:80px 0;



    .box{
      height: auto;
      padding: 30px 24px;
      .title{
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}