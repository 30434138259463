.footer-component{
  margin-bottom: 40px;
  background:linear-gradient(#262E6B,#1A1F46);
  padding: 48px 60px 20px 60px;
  border-radius: 24px;

  .info-text{
    margin: 32px 0 38px 0;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
  .footer-col-title{
    line-height: 24px;
    font-family: "Manrope-SemiBold",sans-serif;
    color: white;
    margin-bottom: 32px;
  }



  ul{
    padding-left: 0;
    li{
      list-style: none;
      margin-bottom: 10px;
    }
    a{
      color: white;
      opacity: 0.8;
      text-decoration: none;
    }
  }

  hr{
    border-color: #EEEEEE;
    margin: 40px 0 20px 0;
  }

  .social-media{
    display: flex;
    justify-content: space-between;
    color: white;
    opacity: 0.7;

  }
}

@media (max-width: 768px) {
.footer-component{
  margin-bottom: 20px;
  padding:30px 40px 30px 40px;
  border-radius: 24px;

  .col{
    margin-top: 30px;
  }

  .footer-col-title{
    line-height: 24px;
    font-family: "Manrope-SemiBold",sans-serif;
    color: white;
    margin-bottom: 32px;
  }

  ul{
    padding-left: 0;
    li{
      list-style: none;
      margin-bottom: 10px;
    }
    a{
      color: white;
      opacity: 0.8;
      text-decoration: none;
    }
  }

  hr{
    border-color: #EEEEEE;
    margin: 40px 0 20px 0;
  }

  .social-media{
    display: flex;
    justify-content: space-between;
    color: white;
    opacity: 0.7;

  }
}
}