.partner-component {
  margin: 56px 0;
  .partner-title{
    font-size: 56px;
    line-height: 72px;
    color: #262E6B;
    font-family: "Neue-SemiBold",sans-serif;
    text-align: center;
    margin-bottom: 32px;
  }

  .partner-box{
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    flex-wrap: wrap;
    .box{
      background-color: #EDEDF2;
      height: 137px;
      margin-bottom: 10px;
      width: 24%;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-right:1% ;
    }
  }
}

@media (max-width: 768px) {
  .partner-component {
    .partner-title{
      font-size: 36px;
      line-height: 44px;
    }

    .partner-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box{
        margin-right: 0;
        margin-bottom: 20px;
        height: 137px;
        width: 49%;
        box-sizing: border-box;
        text-align: center;
        img{
          width: 80%;
        }
      }


    }
  }
}