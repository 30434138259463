.trening-course-page {
  padding: 80px 0;

  .trening-main-text {
    font-size: 20px;
    line-height: 32px;
    font-family: "Manrope-Bold", sans-serif;
    text-align: center;
    margin-bottom: 48px;
  }

  .trening-box {
    text-align: center;
    width: 100%;

    .count {
      font-size: 72px;
      line-height: 80px;
      color: #262e6b;
      margin-bottom: 10px;
      font-family: "Manrope-Bold", sans-serif;
    }

    .text {
      font-size: 18px;
      line-height: 24px;
      color: #4a4a4a;
      font-family: "Manrope-SemiBold", sans-serif;
    }
  }

  .border-box {
    border-left: 2px solid #1c2149;
    border-right: 2px solid #1c2149;
  }

  .trening-main-title {
    font-size: 56px;
    line-height: 72px;
    color: #262e6b;
    font-family: "Neue-SemiBold", sans-serif;
    margin: 100px 0 40px 0;
    text-align: center;
  }

  .course-box {
    background-color: #f2f2fa;
    border-radius: 24px;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    color: #262e6b;
    text-align: center;
    padding: 36px 25px 40px 25px;
    box-sizing: border-box;
    margin-bottom: 28px;
    transition: 0.5s linear;
    &:hover {
      background: #1a1f46;
      .course-box-title {
        color: white;
      }
    }

    .course-box-title {
      transition: 0.5s linear;
      text-align: left;
      font-size: 32px;
      line-height: 43px;
      font-family: "Manrope-Bold", sans-serif;
      text-transform: uppercase;
    }

    img {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .trening-course-page {
    padding: 80px 0;

    .trening-main-text {
      font-size: 16px;
      line-height: 24px;
    }

    .trening-box {
      margin-bottom: 40px;
      .count {
        font-size: 56px;
        line-height: 65px;
        margin-bottom: 0;
      }

      .text {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .border-box {
      border: none;
    }

    .trening-main-title {
      font-size: 40px;
      line-height: 50px;
      margin: 60px 0 40px 0;
    }

    .course-box {
      height: 390px;
      padding: 25px;
      margin-bottom: 24px;

      .course-box-title {
        font-size: 24px;
        line-height: 34px;
      }
      img {
        width: 100%;
      }
    }
    .course-box-4 {
      img {
        width: auto;
      }
    }
  }
}
