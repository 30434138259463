.Navbar {
  background-color: #262E6B;
  position: sticky;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: 1000;

  .desctop-navbar {
    display: flex;
    align-items: center;
    height: 90px;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo-box {
        img {
          cursor: pointer;
          width: 100%;
        }
      }

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
          margin-left: 25px;
          margin-right: 25px;

          a {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;

            display: inline-block;
            text-decoration: none;
            cursor: pointer;
            padding-bottom: 8px;
            border-bottom:3px solid transparent;
          }


          .active-link{
            color: #96A1FF;
            border-bottom: 3px solid #96A1FF;
          }
        }
      }

      .right-box {
        cursor: pointer;
        display: flex;

        .language-box {
          margin-right: 15px;
          background: transparent;
          position: relative;
          z-index: 1111111111;

          .text {
            margin: 0 4px;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }

          .selected-language {
            width: 100%;
            height: 100%;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            cursor: pointer;
           .flag-image{
             border: 2px solid white;
             border-radius: 50%;
           }
          }

          img {
            transition: 0.2s all linear;
          }

          img.close {
            transform: rotate(0);
          }

          img.open {
            transform: rotate(-180deg);
          }

          .select {
            position: absolute;
            bottom: -70px;
            width: 100%;
            border: 1px solid #DDDDDD;
            transition: 0.3s all linear;
            background: #FFFFFF;
            border-radius: 8px;
            overflow: hidden;

            .language {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              cursor: pointer;
              text-transform: uppercase;

              &:hover {
                background-color: #ebf5ff;
              }
            }
          }

          .select.open-lang {
            display: block;
          }

          .select.close-lang {
            display: none;
          }
        }

        .button {
          margin-left: 15px;
          border-radius: 4px;
          background: #e43767;
          display: flex;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          border: none;
          transition: 0.3s all linear;

          &:hover {
            background-color: #fff;
            color: #e43767;
          }
        }
      }
    }
  }

  .mobile-navbar {
    display: none;
  }

  .left-menu {
    display: none;
  }
}

@media (max-width: 768px) {
  .Navbar {

    top: -1px;

    .desctop-navbar {
      display: none;
    }

    .mobile-navbar {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100px;

      .content-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right {
          display: flex;
          align-items: center;

          .button {
            padding: 10px 15px;
            border-radius: 4px;
            background:  #262E6B;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            margin-right: 15px;
            border: none;
            text-decoration: none;
          }

          .language-box {
            margin-right: 10px;
            background: transparent;
            position: relative;
            z-index: 1111111111;

            .text {
              margin: 0 4px;
              color: #fff;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
            }

            .selected-language {
              width: 100%;
              height: 100%;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              cursor: pointer;
            }

            img {
              transition: 0.2s all linear;
            }

            img.close {
              transform: rotate(0);
            }

            img.open {
              transform: rotate(-180deg);
            }

            .select {
              position: absolute;
              bottom: -70px;
              width: 100%;
              border: 1px solid #dedede;
              transition: 0.3s all linear;
              background: #ffffff;

              .language {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                cursor: pointer;
                text-transform: uppercase;

                &:hover {
                  background-color: #ebf5ff;
                }
              }
            }

            .select.open-lang {
              display: block;
            }

            .select.close-lang {
              display: none;
            }
          }
        }
      }
    }

    .left-menu {
      display: block;
      width: 100%;
      height: calc(100vh - 100px);
      position: absolute;
      left: 0;
      background-color: #262E6B;
      transition: 0.2s all linear;

      ul {
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding: 0;

        li {
          margin: 10px 0;

          a {
            color: #ffff;
            font-size: 30px;
            font-weight: 400;
            position: relative;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;

            &::after,
            &::before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 3px;
              background-color: #ffff;
              transform: scaleX(0);
              transform-origin: 50%;
              transition: transform 0.3s;
            }

            &:hover {
              color: #ffff;

              &::before {
                transform-origin: 0% 50%;
                transform: scaleX(1);
              }

              &::after {
                transform-origin: 100% 50%;
                transform: scaleX(1);
              }
            }
          }

          .active {
            &::before {
              transform-origin: 0% 50%;
              transform: scaleX(1);
            }

            &::after {
              transform-origin: 100% 50%;
              transform: scaleX(1);
            }
          }
        }
      }

      .language-box {
        margin-top: 10px;
        background: transparent;
        position: relative;
        z-index: 1111111111;

        .text {
          margin: 0 5px;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
        }

        .selected-language {
          width: 100%;
          height: 100%;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          cursor: pointer;
        }

        img {
          transition: 0.2s all linear;
        }

        img.close {
          transform: rotate(0);
        }

        img.open {
          transform: rotate(-180deg);
        }

        .select {
          position: absolute;
          bottom: -80px;
          width: 100%;
          border: 1px solid #dedede;
          transition: 0.3s all linear;
          background: #ffffff;
          border-radius: 8px;
          overflow: hidden;

          .language {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            cursor: pointer;
            text-transform: uppercase;

            &:hover {
              background-color: #ebf5ff;
            }
          }
        }

        .select.open-lang {
          display: block;
        }

        .select.close-lang {
          display: none;
        }
      }
    }

    .open {
      left: 0;
    }

    .close {
      left: -100%;
    }
  }
}
